import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Alert,
  Spinner,
} from "react-bootstrap";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { resetUser } from "../api/users";
import "../components/layout.scss";
import Layout from "../components/layout";

const StyledContainer = styled(Container)`
  padding-bottom: 40px;
`;
const StyledFormLabel = styled(Form.Label)`
  font-size: 18px;
  font-weight: 500;
  color: #271344;
  text-align: center;
`;

export const query = graphql`
  {
    resetPassword : contentfulResetPassword {
      message {
        message
      }
      title
      redirectText
      redirectLinkText
      input1Title
      input1Placeholder
      buttonTitle
    }
  }
`
const ResetPassword = ({data}) => {
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const { register, handleSubmit, formState, reset } = useForm({ mode: "onChange" });
  const {
    resetPassword:{
      message : {
        message
      },
      title,
      redirectText,
      redirectLinkText,
      input1Title,
      input1Placeholder,
      buttonTitle
    }
  } = data;
  const onSubmit = async info => {
    setLoading(true);
    setError(null);
    setError(null);
    try {
      const newInfo = {
        email: info.email.toLowerCase(),
      };
      const respp = await resetUser(newInfo);
      console.log('respp', respp)
      setLoading(false);
      reset(respp);
      setSuccessMessage("Email sent successfully");
    } catch (err) {
      let message = err?.response?.data?.error || "Email not sent !";
      setError(message);
      setLoading(false);
    }
    setTimeout(function(){ 
      setError(null);
      setSuccessMessage(null);
     }, 5000);
  };
  return (
    <Layout>
      <div id="content" className="site-content">
        <div className="content-inner">
          <div className="">
            <div className="row content-row">
              <div id="primary" className="content-area col-12">
                <main id="main" className="site-main">
                  <article
                    id="post-45"
                    className="post-45 page type-page status-publish hentry"
                  >
                    <div className="entry-content clearfix">
                      <div
                        data-elementor-type="wp-page"
                        data-elementor-id="45"
                        className="elementor elementor-45"
                        data-elementor-settings="[]"
                      >
                        <div className="elementor-section-wrap">
                          <section
                            className="elementor-section elementor-top-section elementor-element elementor-element-f6893e0 elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                            data-id="f6893e0"
                            data-element_type="section"
                            data-settings='{"stretch_section":"section-stretched"}'
                          >
                            <div className="elementor-container reset-page-container elementor-column-gap-default ">
                              <div className="elementor-widget-container reset-page-image-container  cloud-image-container">
                                <div className="cms-image-single reset-page-image-box cms-animation-left-to-right ">
                                  <div className="cms-img-box ">
                                    <StaticImage
                                      placeholder="none"
                                      width="100"
                                      height="104"
                                      src="../images/image-clound.png"
                                      className="attachment-full size-full img-1"
                                      alt=""
                                      loading="lazy"
                                    />{" "}

                                    <StaticImage
                                      placeholder="none"
                                      width="133"
                                      height="137"
                                      src="../images/image-clound.png"
                                      className="attachment-full size-full img-2"
                                      alt=""
                                      loading="lazy"
                                    />{" "}
                                  </div>
                                </div>{" "}
                                <div className="cms-image-single reset-page-image-box-2 cms-animation-bottom-to-top ">
                                  <div className="cms-img-box">
                                    <StaticImage
                                      placeholder="none"
                                      width="103"
                                      height="107"
                                      src="../images/image-home-2.png"
                                      className="attachment-full size-full"
                                      alt=""
                                      loading="lazy"
                                    />{" "}
                                  </div>
                                </div>{" "}
                              </div>

                              <div
                                className="elementor-element reset-page-form-container elementor-element-8832270 elementor-widget elementor-widget-cms_heading"
                                data-id="8832270"
                                data-element_type="widget"
                                data-widget_type="cms_heading.default"
                              >
                                <div className="elementor-widget-container reset-container-form">
                                  <div className="cms-heading-wrapper cms-heading-layout1 wow pulse">
                                    <h3 className="custom-heading">
                                      <span>{title}</span>
                                    </h3>
                                    <div className="custom-heading-description ">
                                     {message}{" "}
                                    </div>
                                  </div>{" "}
                                </div>
                                <div
                                  className="elementor-element elementor-element-6f927b2 elementor-widget elementor-widget-cms_image_single mb-3"
                                  data-id="6f927b2"
                                  data-element_type="widget"
                                  data-widget_type="cms_image_single.default"
                                >
                                  <StyledContainer>
                                    <Row className="justify-content-center reset-container-form2">
                                      <Col sm="8" md="9">
                                        {error && (
                                          <Alert variant="danger">
                                            {error}
                                          </Alert>
                                        )}
                                        {successMessage && (
                                          <Alert variant="success">
                                            {successMessage}
                                          </Alert>
                                        )}
                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                          <Form.Group controlId="formBasicEmail">
                                            <StyledFormLabel>
                                              <b>{input1Title}</b>
                                            </StyledFormLabel>
                                            <Form.Control
                                              name="email"
                                              placeholder={input1Placeholder}
                                              type="email"
                                              ref={register({
                                                required: true,
                                              })}
                                            />
                                          </Form.Group>

                                          <Button
                                            block
                                            size="lg"
                                            variant="primary"
                                            type="submit"
                                            disabled={
                                              !formState.isValid || isLoading
                                            }
                                          >
                                            {buttonTitle}{" "}
                                            {isLoading && (
                                              <Spinner
                                                animation="border"
                                                as="span"
                                                animation="border"
                                                size="sm"
                                              />
                                            )}
                                          </Button>
                                        </Form>
                                        <StyledFormLabel className="back-to-login">
                                          <p>
                                            {" "}
                                            {redirectText}{" "}
                                            <a href="/signin">{redirectLinkText}</a>
                                          </p>
                                        </StyledFormLabel>
                                      </Col>
                                    </Row>
                                  </StyledContainer>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </article>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ResetPassword;
